<template>
<div>
  <v-data-table
    :headers="headers"
    :items="items"
    sort-by="calories"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Users</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
                
                  <v-text-field
                    dense
                    v-model="search"
                    label="Search"
                    class="mr-4"
                    append-icon="mdi-magnify"

                  ></v-text-field>

            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="newItem"
            >
              New user
              <v-icon right>
                mdi-plus
              </v-icon>
            </v-btn>                
      
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>

  <v-dialog
          v-model="dialogDelete"
          max-width="500px"
        >

          <v-card>
            <v-toolbar dense color="primary" dark>
              <v-spacer />
              <v-icon @click="dialogDelete = false">
                mdi-close
              </v-icon>
            </v-toolbar>
            <v-card-title>
              <span class="headline">Delete user</span>
            </v-card-title>

            <v-card-text>
              <deleteuser @save="users" @close="dialogDelete = false" :editUser="editedItem" :key="editedIndex" />
            </v-card-text>
          </v-card>
        </v-dialog>

  <v-dialog
          v-model="dialog"
          max-width="500px"
        >

          <v-card>
            <v-toolbar dense color="primary" dark>
              <v-spacer />
              <v-icon @click="dialog = false">
                mdi-close
              </v-icon>
            </v-toolbar>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <createuser @save="users" :editUser="editedItem" :key="editedIndex" />
            </v-card-text>
          </v-card>
        </v-dialog>        
  </div>
</template>

<script>

import adminService from "@/services/AdminService"

import createuser from "@/components/auth/sub/CreateUser"
import deleteuser from "@/components/auth/sub/DeleteUser"


  export default {
    components:{
      createuser,
      deleteuser
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
      search: "",
      headers: [
        {
          text: 'Username',
          align: 'start',
          sortable: false,
          value: 'userName',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
      },
      // defaultItem: {
      // },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New User' : 'Edit User'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
     async initialize () {
        await this.users();
      },

     async users () {
        this.$store.dispatch("util/setLoading", {visible: true, text: "Fetching users"});
        this.items = await adminService.Users()
        this.$store.dispatch("util/resetLoading");
      },

      newItem(){
        this.editedIndex = -1
        this.editedItem = null
        this.dialog = true
      },

      editItem (item) {
        this.editedIndex = this.items.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
         this.editedIndex = this.items.indexOf(item)
         this.editedItem = Object.assign({}, item)
         this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.items.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        // this.$nextTick(() => {
        //   this.editedItem = Object.assign({}, this.defaultItem)
        //   this.editedIndex = -1
        // })
      },

      closeDelete () {
        this.dialogDelete = false
        // this.$nextTick(() => {
        //   this.editedItem = Object.assign({}, this.defaultItem)
        //   this.editedIndex = -1
        // })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem)
        } else {
          this.items.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>