<template>
<div>
    <v-card flat width="400">
      <v-card-text>
        <v-form @submit.prevent="register" class="px-5">
          <v-text-field
            v-model="email"
            :disabled="inEditMode"
            :error-messages="emailErrors"
            label="Email"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            prepend-icon="mdi-email"
          />
          <v-text-field
            v-model="password"
            v-if="!inEditMode"
            :type="showPassword ? 'text' : 'password'"
            :error-messages="passwordErrors"
            label="Password"
            required
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            v-model="confirmPassword"
            v-if="!inEditMode"
            :type="showPassword ? 'text' : 'password'"
            :error-messages="confirmPasswordErrors"
            label="Confirm password"
            required
            @input="$v.confirmPassword.$touch()"
            @blur="$v.confirmPassword.$touch()"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />

        <v-select
                    v-model="rolesSelected"
                    multiple
                    prepend-icon="mdi-google-circles-group"
                    :items="roles"
                    label="User roles"
                    item-text="name"
                    item-value="id"
                    :error-messages="roleErrors"
                    @input="$v.roles.$touch()"
                    @blur="$v.roles.$touch()"                    
                    
        ></v-select>
          

        
          
        </v-form>
      </v-card-text>

          <v-card-text v-if="showResponse">
          <div v-if="!status">
            <v-alert dense outlined type="error">
              <p v-for="e in errors" :key="e">{{ e }}</p>
            </v-alert>
          </div>
          <div v-else>
            <v-alert v-if="inEditMode" dense text type="success">{{ successMessage }}</v-alert>
          </div>
        </v-card-text>
      
      <v-card-actions class="my-1">
        <v-spacer />
        <v-btn @click="reset" color="error">Reset</v-btn>
        <v-btn @click="register" color="primary" :loading="loading">Save</v-btn>
      </v-card-actions>
    </v-card>
    </div>
</template>

<script>


import adminService from "@/services/AdminService"

import { validationMixin } from "vuelidate";
import { required, requiredIf, minLength, email, sameAs } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { minLength: minLength(6), required: requiredIf(function() {
      return !this.inEditMode
    })  },
    confirmPassword: { sameAsPassword: sameAs("password") },
    roles: { required, minLength: minLength(1)  },
  },
  props:{
    editUser: {default: null, type: Object} 
  },
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      rolesSelected: [],
      status: false,
      showResponse: false,
      showPassword: false,
      errors: null,
      loading:false,
      roles: []
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if(this.inEditMode) return errors
      if(!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Password must be at least 6 characters long");
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
    roleErrors(){
     const errors = [];
      if (!this.$v.roles.$dirty) return errors;
      !this.$v.roles.required &&
        errors.push("Must be assigned a role");
      return errors;
    },   

    confirmPasswordErrors() {
      if(this.inEditMode ) return errors
      const errors = [];
      if (!this.$v.confirmPassword.$dirty) return errors;
      !this.$v.confirmPassword.sameAsPassword &&
        errors.push("Passwords does not match");
      return errors;
    },
 
    user(){
        return this.$store.getters["auth/user"];
    },
    inEditMode(){
      return !(this.editUser == null)
    },
    successMessage(){
      return this.inEditMode ? "Användaren har uppdaterats" : "Användaren har skapats"
    }

  },
  mounted() {
      this.init();
  },
  methods: {
    async init(){
        this.roles = await adminService.Roles();

        if(this.inEditMode)
          this.setEditForm()
    },
    setEditForm(){
      this.email = this.editUser.userName;
      this.rolesSelected = this.editUser.roles;
    },
    async register() {
      this.$v.$touch();
       if (this.$v.$invalid) {
        return 
      } else {
          this.loading = true;
          let result = null
          if(this.inEditMode)
            result = await adminService.EditUser(this.editUser.userId, this.rolesSelected)
          else
            result = await adminService.CreateUser(this.email, this.password, this.rolesSelected)
            
            

         this.loading = false;
         this.status = result.succeeded
         this.errors = result.errors
        this.showResponse = true

        if(result.succeeded)
          this.$emit('save')

      }
    },
    reset() {
      this.email = "";
      this.password = "";
      this.confirmPassword = "";
      this.status = true;
      this.showPassword = false
      this.rolesSelected = []
    }
  }
};
</script>

<style scoped></style>