import Vue from "vue";



export default new (class AccountService {


  

  async Roles() {
    const path = "/api/admin/roles";
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }      
  

  async Users() {
    const path = "/api/admin/Users";
    let result = null;
    await Vue.prototype.$axios
      .get(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }     

  async CreateUser( email, password, roles ) {
    const path = "/api/admin/CreateUser";
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: {email: email, password: password, roles: roles},
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  } 

  async EditUser( userId, roles ) {
    const path = `/api/admin/EditUser/${userId}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: {roles: roles },
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }
  
  async DeleteUser(userId) {
    const path = `/api/admin/DeleteUser/${userId}`;
    let result = null;
    await Vue.prototype.$axios.delete(path)
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  }

})();
