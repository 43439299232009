<template>
<div>
    <v-card flat width="400">
      <v-card-text>
        Delete user {{editUser.userName}}
      </v-card-text>

          <v-card-text v-if="showResponse">
          <div v-if="!status">
            <v-alert dense outlined type="error">
              <p v-for="e in errors" :key="e">{{ e }}</p>
            </v-alert>
          </div>
          <div v-else>
            <v-alert dense text type="success">{{ successMessage }}</v-alert>
          </div>
        </v-card-text>


      
      <v-card-actions class="my-1">
        <v-spacer />
        <v-btn small @click="close" color="error">Back</v-btn>
        <v-btn small @click="submit" color="primary" :loading="loading">Delete</v-btn>
      </v-card-actions>
    </v-card>
    </div>
</template>

<script>


 import adminService from "@/services/AdminService"


export default {
  props:{
    editUser: {default: null, type: Object} 
  },
  data() {
    return {
      status: false,
      showResponse: false,
      errors: null,
      loading:false,
    };
  },
  computed: {
   
 
    user(){
        return this.$store.getters["auth/user"];
    },

    successMessage(){
      return "User has been deleted";
    }

  },
  mounted() {
  },
  methods: {

    close(){
      this.$emit('close')
    },

    async submit() {
      this.loading = true;
      var result = await adminService.DeleteUser(this.editUser.userId)

       this.loading = false;
         this.status = result.succeeded
         this.errors = result.errors
        this.showResponse = true
        this.$emit('save') 
        if(result.succeeded)
        {
          setTimeout(() => {
          this.$emit('close')  
          }, 1000);
        }
    }
  }
};
</script>

<style scoped></style>