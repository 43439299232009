<template>
<div>
       <v-sheet color="grey lighten-3" min-height="10vh">
    <v-container fill-height>
      <v-row dense justify="start" >
    <div class="transition-swing text-h4 mt-10 mb-2" >
    Admin
    </div>
      </v-row>
    </v-container>
  </v-sheet>

<v-sheet color="grey lighten-4" min-height="60vh">
  <v-container>



    <v-row dense v-if="isAdmin">
      <v-col cols="3">
        <v-card class="mx-auto" width="300">
          <v-list>

            <v-list-group :value="true" no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Users</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                v-for="(m, i) in membermenu"
                :key="i"
                link
                @click="setActiveComponent(m.component)"
              >
                <v-list-item-title v-text="m.title"></v-list-item-title>

                <v-list-item-icon>
                  <v-icon v-text="m.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="8">
        <!-- <p
          class="transition-swing text-h6 mb--1 mt-2 font-weight-medium text-left"
        >{{ menuitem.text }}</p> -->
        <component v-bind:is="activeComponent"></component>
      </v-col>
    </v-row>
    <v-row v-if="!isAdmin" justify="center" align-content="center">
      <v-alert prominent type="error" class="mt-10">
        <v-row align="center">
          <v-col class="grow">
            <strong>Du har inte behörighet till denna sida</strong>
          </v-col>
        </v-row>
      </v-alert>
    </v-row>
  </v-container>
</v-sheet>
</div>
</template>

<script>
//import MyAccount from "@/components/auth/MyAccount.vue";
import userlist from "@/components/auth/UserList.vue";


export default {
  name: "admin",
  components: {
      userlist
  },

  data: () => ({
    activeComponent: "",
    membermenu: [
      
      { title: "All users", icon: "", component: "userlist" }
    ],
    menuitem: 1,
  }),
  computed: {
    user() {
      const user = this.$store.getters["auth/user"];
      return user;
    },
    isAdmin(state) {
      return state.user.data.roles.includes("Administrator")
    }
  },
  methods: {
    setActiveComponent(val) {
      this.activeComponent = val;
    }
  }
};
</script>

